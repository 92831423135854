import React from 'react'
import jwt from 'jwt-decode'

import '../Assets/Css/NavigationBar.css'

interface IProps {
}

interface IState {
    Token: string | null,
    Time: string,
    TimeInterval: NodeJS.Timer | null
}

export default class NavigationBar extends React.Component<IProps, IState> {

    constructor(props : any){
        super(props)
        this.state = {
            Token : '',
            Time : '',
            TimeInterval : null
        }
    }

    componentDidMount(){
        this.setState({
            Token : localStorage.getItem('TOKEN-UN1FL3X')
        }, () => {
            this.setState({TimeInterval : setInterval(
                () => this.CalcularHoraToken(), 1000
            )})
        })
    }
    
    componentWillUnmount(){
        if(this.state.TimeInterval) clearInterval(this.state.TimeInterval)
    }

    CalcularHoraToken(){
        if(this.state.Token){
            var decoded : any = jwt(this.state.Token)
            var date : any = new Date(decoded.exp * 1000)
            var hoy : any = new Date()
    
            var diff = Math.round((  date - hoy ) / 1000)
            if(diff < 0){
                alert("Debe iniciar sesión nuevamente.")
                localStorage.removeItem('TOKEN-UN1FL3X')
                window.location.reload()
            }else{
                var time = diff
                var hours = Math.floor( time / 3600 )
                var minutes : any = Math.floor( (time % 3600) / 60 )
                var seconds : any = time % 60
                minutes = minutes < 10 ? '0' + minutes : minutes
                seconds = seconds < 10 ? '0' + seconds : seconds
                var result = hours + ":" + minutes + ":" + seconds
                this.setState({ Time : result})
            }
        }
    }

    CerrarSesion(){
        localStorage.removeItem('TOKEN-UN1FL3X')
    }

    AsignarAdministrador(){
        if(this.state.Token){
            let TokenDecoded : any = jwt(this.state.Token)
            if(TokenDecoded.Administrator === 'True'){
                return(
                    <li><a href="/Administracion">Administración</a></li>
                )
            }
        }
    }

    render(){
        return(
            <div className="card">
                <header className="toolbar">
                    <nav className="toolbar_navigation">
                        <div>
                        </div>
                        <div className="toolbar_logo">
                            <a href="/Documento">Beer Bonus Automation</a>
                        </div>
                        <div className="spacer"/>
                        <div className="toolbar_navigation_items">
                            <ul>
                                {this.AsignarAdministrador()}
                                <li style={{color:'white'}}>{this.state.Time}</li>
                                <li><a href="/" onClick={this.CerrarSesion}>Salir</a></li>
                            </ul>
                        </div>
                    </nav>
                </header>
            </div>
        )
    }
}