import React from 'react'
import Loading from '../Components/Loading'
import { UsuarioService } from '../../Domain/Services/Usuario.service'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import '../Assets/Css/Login.css'

interface Credencial{
    PKID: number,
    Usuario: string,
    Contraseña: string
    Documento: boolean,
    Administrador: boolean
}

interface IProps {
}

interface IState {
    Credencial: Credencial,
    IsLoading: boolean
}

export default class Login extends React.Component<IProps, IState> {

    constructor(props : any){
        super(props)
        this.state = {
            Credencial:{
                PKID: 0,
                Usuario: '',
                Contraseña: '',
                Documento: false,
                Administrador: false
            },
            IsLoading: false
        }
    }

    componentDidMount(){
        localStorage.removeItem('TOKEN-UN1FL3X')
    }

    HandleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            Credencial:{
                ...this.state.Credencial,
                [e.target.name]: e.target.value
            }
        })
    }

    HandleSubmit = (e : React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.setState({
            IsLoading: true
        }, () => {this.Autenticar()})
    };

    Autenticar(){ 
        UsuarioService.Autenticar(this.state.Credencial)
        .then( (data : any) => {
            console.log(data)
            if(data.success){
                var info = JSON.parse(data.data);
                localStorage.setItem('TOKEN-UN1FL3X',info.access_token)
                window.location.assign('Documento')
            }else{
                alert(data.data)
            }
        })
        .catch( error => {
            alert(error)
        })
        .finally( () => {
            this.setState({
                IsLoading: false
            })
        })
    }

    render(){
        return (
            <>
                {this.state.IsLoading ? 
                    <Loading Load={this.state.IsLoading}/>
                    :
                    <div>
                        <header className="toolbar">
                            <nav className="toolbar_navigation">
                            </nav>
                        </header>
                            
                        <div className="authBox">
                            <div className="box">
                                <div className="subtitleAuth">Iniciar sesión en</div>
                                <div className="titleAuth">Beer Bonus Automation</div>
                                <form autoComplete="off" onSubmit={this.HandleSubmit}>
                                    <div className="p-col-fixed p-mt-4">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12 p-mt-3">
                                                <InputText 
                                                    value={this.state.Credencial.Usuario}
                                                    name="Usuario"
                                                    onChange={this.HandleChange}
                                                    type="text" 
                                                    placeholder="Usuario"/>
                                            </div>
                                        </div>
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12 p-mt-3">
                                                <InputText 
                                                    value={this.state.Credencial.Contraseña}
                                                    name="Contraseña"
                                                    onChange={this.HandleChange}
                                                    type="password"
                                                    placeholder="Contraseña"/>
                                            </div>
                                        </div>
                                        <div className="p-mt-1">
                                            <Button 
                                                label="Ingresar"
                                                style={{
                                                    background:'#293686',
                                                    border:'#293686',
                                                    width:'100%'
                                                }}
                                                className="p-ml-auto"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}