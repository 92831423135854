import React from 'react'
import jwt from 'jwt-decode'
import Loading from '../Components/Loading'
import ReglasDataTable from '../Components/ReglasDataTable'
import Paginator from '../Components/Paginator'
import EstadoRegla from '../Components/EstadoRegla'
import { TabView, TabPanel } from 'primereact/tabview';
import { DocumentoService } from '../../Domain/Services/Documento.service'

interface IEstadoRegla {
    Codigo: string,
    Detalle: string,
    FechaProceso: Date,
    Mensaje: string,
    PKID: number
}

interface ISniper {
    code: number,
    name: string
}

interface IRegistrosPagina {
    RegistrosPagina: number
}

interface IPaginado {
    NumeroPagina: number,
    RegistrosPagina: number,
    NumeroPaginas: number,
    fil_EstadoRegla: number,
    fil_ID : '',
    fil_DA : '',    
    fil_Sucursal : '',
    fil_Regla : '',
    fil_SKU : '',
    fil_Canal : '',
    fil_SubCanal : '',
    fil_EstadoReglaDesactivacion: number
}

interface ITipoRegla {
    PKID: number,
    Nombre: string
}

interface IProps {
}

interface IState {
    IsLoading: boolean,
    TiposRegla: Array<ISniper>,
    TipoRegla: ITipoRegla,
    ActiveIndex: number,
    Paginado: IPaginado
    Reglas: Array<object>,
    RegistrosPagina: IRegistrosPagina,
    SelectedEstado: any,
    SelectedEstadoDesactivacion: any,
    EstadoRegla: IEstadoRegla,
    OpenModal: boolean,
    Filtro: any
}

export default class Documento extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props)
        this.state = {
            IsLoading: true,
            TiposRegla: [],
            TipoRegla: {
                PKID: 0,
                Nombre: ''
            },
            ActiveIndex: 0,
            Paginado: {
                NumeroPagina: 1,
                RegistrosPagina: 50,
                NumeroPaginas: 0,
                fil_EstadoRegla: -2,
                fil_ID : '',
                fil_DA : '',
                fil_Sucursal : '',
                fil_Regla : '',
                fil_SKU : '',
                fil_Canal : '',
                fil_SubCanal : '',
                fil_EstadoReglaDesactivacion: 0
            },
            Reglas: [],
            RegistrosPagina: {
                RegistrosPagina: 50
            },
            SelectedEstado: '',
            SelectedEstadoDesactivacion: '',
            EstadoRegla: {
                Codigo: '',
                Detalle: '',
                FechaProceso: new Date(),
                Mensaje: '',
                PKID: 0
            },
            OpenModal: false,
            Filtro: {
                FechaInicio: new Date(),
                FechaFin: new Date()
            }
        }
    }

    componentDidMount() {
        this.TipoReglas()
    }

    TipoReglas() {
        DocumentoService.ListarHojaDocumento()
            .then((data: any) => {
                if (data.success) {
                    var info = JSON.parse(data.data);
                    var aRegla: any = []
                    Object.keys(info).forEach(function (key) {
                        var iRegla = {
                            code: info[key].PKID,
                            name: info[key].Nombre
                        }
                        aRegla.push(iRegla)
                    });
                    var cRegla = {
                        PKID: aRegla[0].code,
                        Nombre: aRegla[0].name
                    };

                    if (this.state.TipoRegla.PKID>0)
                    {
                        cRegla = this.state.TipoRegla;
                    }
                    
                    this.setState({
                        TiposRegla: aRegla,
                        TipoRegla: cRegla
                    }, () => this.ListarReglasPorTipo())
                } else {
                    alert(data.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
            .finally(() => {
                this.setState({
                    IsLoading: false
                })
            })
    }

    ListarReglasPorTipo() {
        this.setState({
            IsLoading: true
        }, () => {
            /* console.log('this.ListarReglasPorTipo');
            console.log(this.state.TipoRegla);
            console.log(this.state.Paginado);
            console.log(this.state.ActiveIndex); */
            DocumentoService.ListarReglasPorTipo({ HojaDocumento: this.state.TipoRegla, Paginado: this.state.Paginado })
                .then((data: any) => {
                    if (data.success) {
                        this.setState({
                            Reglas: data.data.Regla,
                            Paginado: data.data.Paginado
                        })
                    } else {
                        alert(data.data)
                    }
                })
                .catch((error) => {
                    alert(error)
                })
                .finally(() => {
                    this.setState({
                        IsLoading: false
                    })
                })
        })
    }

    HandleChange = (e: any) => {
        console.log('Target');
        console.log(e.target);
        switch (e.target.name) {
            default:
                this.setState({
                    Filtro: {
                        ...this.state.Filtro,
                        [e.target.name]: e.target.value
                    }
                });
                console.log('Filtro');
                console.log(this.state.Filtro);
                break;
        }
    }

    Filter = (Event : any) => {
        this.setState({
            Paginado:{
                ...this.state.Paginado,
                NumeroPagina : 1,
                [Event.target.name] : Event.target.value
            }
        })
    }

    FilterKeyDown = (Event : any) => {
        if (Event.key === 'Enter') {
            this.ListarReglasPorTipo();
            //this.CargarHojaDocumento()
        }
    }

    HandleFileUpload = (Event: any, RefFile: any) => {
        if (Event.files[0].type !== 'application/vnd.ms-excel.sheet.macroEnabled.12' &&
            Event.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
            Event.files[0].type !== 'application/vnd.ms-excel') {
            alert('No puede subir un documento con diferente formato excel')
        } else {
            var Token: string | null = localStorage.getItem('TOKEN-UN1FL3X')
            if (Token) {
                var Decoded: any = jwt(Token)
                console.log('HandleFileUpload');
                console.log(Event);
                const FData = new FormData()
                FData.append("Files", Event.files[0]);
                FData.append("Usuario", Decoded.sub);
                FData.append("Anulacion", Event.options.props.id === 'fileCancel' ? "true" : "false");
                this.setState({
                    IsLoading: true
                }, () => {
                    DocumentoService.UploadFile(FData)
                        .then((data: any) => {
                            if (!data.success) {
                                alert(data.data)
                            } else {
                                this.setState({
                                    IsLoading: true
                                }, () => this.TipoReglas())
                            }
                        })
                        .catch((error) => {
                            alert(error)
                        })
                        .finally(() => {
                            this.setState({
                                IsLoading: false
                            })
                        })
                })
            }
        }
        RefFile.current.files = ''
        RefFile.current.state.files = ''
    }

    HandleFilter = (Event: any) => {
        var Token: string | null = localStorage.getItem('TOKEN-UN1FL3X')
        if (Token) {
            console.log('HandleFilter');
            console.log(Event);
            console.log(this.state.Filtro);

            this.setState({
                IsLoading: true
            }, () => {
                DocumentoService.DownloadFile(this.state.Filtro)
                    .then((data: any) => {
                        console.log('After downloadFile');
                        console.log(data);
                        if (!data.success) {
                            alert(data.data)
                        } else {
                            this.setState({
                                IsLoading: true
                            }, () => this.TipoReglas());

                            data=JSON.parse(data.data);
                            //console.log(data);

                            const byteCharacters = window.atob(data.Archivo);
                            //var buffer = Buffer.from(data.Archivo,'base64');
                            //const byteCharacters = buffer.toString('base64');

                            const byteNumbers = new Array(byteCharacters.length)
                            for (let i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i)
                            }
                            const byteArray = new Uint8Array(byteNumbers);
                            const url = window.URL.createObjectURL(new Blob([byteArray]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', data.NombreArchivo);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch((error) => {
                        alert(error)
                    })
                    .finally(() => {
                        this.setState({
                            IsLoading: false
                        })
                    })
            })
        }
    }

    ChangeTab = (e: { index: number }) => {
        this.setState({
            ActiveIndex: e.index,
            Reglas: [],
            TipoRegla: {
                PKID: this.state.TiposRegla[e.index].code,
                Nombre: this.state.TiposRegla[e.index].name
            },
            Paginado: {
                NumeroPagina: 1,
                RegistrosPagina: 50,
                NumeroPaginas: 0,
                fil_EstadoRegla: -2,
                fil_ID : '',
                fil_DA : '',    
                fil_Sucursal : '',
                fil_Regla : '',
                fil_SKU : '',
                fil_Canal : '',
                fil_SubCanal : '',
                fil_EstadoReglaDesactivacion: 0
            },
            RegistrosPagina: {
                RegistrosPagina: 50
            }
        }, () => this.ListarReglasPorTipo())
    };

    HandlePreviousLL = () => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: 1,
                RegistrosPagina: 50,
                NumeroPaginas: 0
            }
        }, () => this.ListarReglasPorTipo())
    }

    HandlePrevious = () => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: this.state.Paginado.NumeroPagina - 1
            }
        }, () => this.ListarReglasPorTipo())
    }

    HandleFollowing = () => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: this.state.Paginado.NumeroPagina + 1
            }
        }, () => this.ListarReglasPorTipo())
    }

    HandleFollowingLL = () => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: this.state.Paginado.NumeroPaginas
            }
        }, () => this.ListarReglasPorTipo())
    }

    HandleRegistroChange = (e: any) => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: 1,
                RegistrosPagina: e.value.RegistrosPagina,
            },
            RegistrosPagina: e.value
        }, () => this.ListarReglasPorTipo())
    }

    OnChangeEstado = (e: any) => {
        var cPaginado;
        console.log(e);
        if (e.value === 'Sincronizado') {
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                fil_EstadoRegla: 0
            }
        } else if (e.value === 'Error') {
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                fil_EstadoRegla: 1
            }
        } else if (e.value === 'No Sincronizado') {
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                fil_EstadoRegla: -1
            }
        } else {
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                fil_EstadoRegla: -2
            }
        }
        this.setState({
            Paginado: cPaginado,
            SelectedEstado: e.value
        }, () => this.ListarReglasPorTipo())
    }

    OnChangeEstadoDesactivacion = (e: any) => {
        var cPaginado;
        console.log(e);
        if (e.value === 'Activo') {
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                fil_EstadoReglaDesactivacion: 10
            }
        } else if (e.value === 'Pendiente') {
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                fil_EstadoReglaDesactivacion: 11
            }
        } else if (e.value === 'Desactivado') {
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,
                fil_EstadoReglaDesactivacion: 12
            }
        } else {
            cPaginado = {
                ...this.state.Paginado,
                NumeroPagina: 1,                
                fil_EstadoReglaDesactivacion: 0
            }
        }
        this.setState({
            Paginado: cPaginado,
            SelectedEstado: e.value
        }, () => this.ListarReglasPorTipo())
    }

    HandleSelectRow = (EstadoRegla: {
        Codigo: string,
        Detalle: string,
        FechaProceso: Date,
        Mensaje: string,
        PKID: number
    }) => {
        this.setState({
            EstadoRegla: EstadoRegla
        }, () => {
            this.setState({ OpenModal: true })
        })
    }

    render() {
        return (
            <>
                {this.state.IsLoading ?
                    <Loading Load={this.state.IsLoading} />
                    :
                    <div style={{ padding: '1vh' }}>
                        <TabView
                            activeIndex={this.state.ActiveIndex}
                            onTabChange={(e) => this.ChangeTab(e)}>
                            {
                                this.state.TiposRegla.map((TipoRegla: { code: number, name: string }, index: number) =>
                                    <TabPanel header={TipoRegla.name} key={index}>
                                        <ReglasDataTable
                                            HandleFileUpload={this.HandleFileUpload}
                                            TipoRegla={TipoRegla}
                                            Regla={this.state.Reglas}
                                            SelectedEstado={this.state.SelectedEstado}
                                            SelectedEstadoDesactivacion={this.state.SelectedEstadoDesactivacion}
                                            OnChangeEstado={this.OnChangeEstado}
                                            OnChangeEstadoDesactivacion={this.OnChangeEstadoDesactivacion}
                                            Loading={this.state.IsLoading}
                                            HandleSelectRow={this.HandleSelectRow}
                                            Filtro={this.state.Filtro}
                                            HandleFilter={this.HandleFilter}
                                            HandleChange={this.HandleChange}
                                            Filter = {this.Filter}
                                            FilterKeyDown = {this.FilterKeyDown}
                                            Paginado = {this.state.Paginado}
                                        />
                                        <Paginator
                                            Paginado={this.state.Paginado}
                                            HandlePreviousLL={this.HandlePreviousLL}
                                            HandlePrevious={this.HandlePrevious}
                                            HandleFollowing={this.HandleFollowing}
                                            HandleFollowingLL={this.HandleFollowingLL}
                                            RegistrosPagina={this.state.RegistrosPagina}
                                            OnRegistroChange={this.HandleRegistroChange} />
                                        <EstadoRegla
                                            OpenModal={this.state.OpenModal}
                                            CloseModal={() => this.setState({ OpenModal: false })}
                                            EstadoRegla={this.state.EstadoRegla} />
                                    </TabPanel>)
                            }
                        </TabView>
                    </div>
                }
            </>
        )
    }
}