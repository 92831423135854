import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner'

import '../Assets/Css/Spinner.css'

export default function Loading(props : any){
    return(
        <>
            {props.Load ? 
                    <div className="PosSpinner">
                        <ProgressSpinner/>
                        <p>Consultando...</p>
                    </div>
                :
                <>
                </>
            }
        </>
    )
}