import React from 'react'
import jwt from 'jwt-decode'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import NavigationBar from './Infrastructure/Components/NavigationBar'
import Login from "./Infrastructure/Pages/Login"
import Documento from "./Infrastructure/Pages/Documento"
import Administracion from './Infrastructure/Pages/Administracion'

interface IProps {
}

interface IState {
    Token: string | null
}

export default class App extends React.Component<IProps, IState> {

    constructor(props : any){
        super(props)
        this.state = {
            Token: localStorage.getItem('TOKEN-UN1FL3X')
        }
    }

    render(){

        const AutenticadoAdministrador = () => {
            if(this.state.Token){
                var decoded : any = jwt(this.state.Token)
                return decoded.Administrator
            }else{
                return false
            }
        }

        const PrivateRoute = ({component: Component, authed, ...rest} : any) => {
            return (
                <Route
                    {...rest}
                    render={(props : any) => (authed === true ? 
                    <>
                    <NavigationBar />
                        {
                            AutenticadoAdministrador() === 'True'? 
                            <>
                                <Route path="/Documento" component={Documento} exact/>
                                <Route path="/Administracion" component={Administracion}/>
                            </>
                            :
                            <>
                                <Route path="/Documento" component={Documento} exact/>
                            </>
                        }
                    </>
                :
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />)} 
                />
            )
        }

        return(
            <BrowserRouter  basename="">
                <Switch>
                    <Route exact path="/" component={Login}></Route>
                    <PrivateRoute 
                        authed={this.state.Token ? true : false} 
                        component={Documento}/>
                </Switch>
            </BrowserRouter>
        )
    }
}