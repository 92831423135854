import React, { RefObject } from 'react'
import jwt from 'jwt-decode'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FileUpload } from 'primereact/fileupload'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'

import '../Assets/Css/DataTable.css'
import { InputText } from 'primereact/inputtext'

interface IProps {
    HandleFileUpload: any,
    TipoRegla: ({ code: number, name: string }),
    Regla: Array<object>,
    SelectedEstado: any,
    SelectedEstadoDesactivacion: any,
    OnChangeEstado: any,
    OnChangeEstadoDesactivacion: any,
    Loading: boolean,
    HandleSelectRow: any,
    HandleFilter: any,
    Filtro: ({
        FechaInicio: Date,
        FechaFin: Date,
    }),
    Paginado: any,
    Filter: any,
    FilterKeyDown: any,
    HandleChange: any,
}

interface IState {
    RefFile: RefObject<FileUpload>,
    Estados: Array<string>,
    EstadosDesactivacion: Array<string>,
    ExpandedRows: Array<object>
}

export default class ReglasDataTable extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props)
        this.state = {
            RefFile: React.createRef<FileUpload>(),
            Estados: [
                'Sincronizado',
                'No Sincronizado',
                'Error'
            ],
            EstadosDesactivacion: [
                'Activo',
                'Pendiente',
                'Desactivado'
            ],
            ExpandedRows: []
        }
    }

    Header() {
        var Token: string | null = localStorage.getItem('TOKEN-UN1FL3X')
        if (Token) {
            const chooseOptions = { label: 'Desactivar reglas', icon: 'pi pi-times', className: 'p-button-danger' };
            var Decoded: any = jwt(Token)
            return (
                <div className="p-d-flex">
                    <div className='p-mr-2 p-as-center'>
                        {Decoded.Document === 'True' ?
                            <FileUpload
                                ref={this.state.RefFile}
                                mode="basic"
                                name="fileUpload"
                                id="fileUpload"
                                auto
                                uploadHandler={(event) => this.props.HandleFileUpload(event, this.state.RefFile)}
                                customUpload
                                accept=".xlsm,application/msexcel,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                chooseLabel="Subir reglas" />
                            :
                            <></>
                        }
                    </div>

                    <div className="p-mr-2 p-as-center">
                        {Decoded.Document === 'True' ?
                            <FileUpload
                                ref={this.state.RefFile}
                                mode="basic"
                                name="fileCancel"
                                id="fileCancel"
                                auto
                                uploadHandler={(event) => this.props.HandleFileUpload(event, this.state.RefFile)}
                                customUpload
                                accept=".xlsm,application/msexcel,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                chooseOptions={chooseOptions}
                            />
                            :
                            <></>
                        }
                    </div>

                    <div className="p-ml-auto">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-4">
                                    <label
                                        className="p-d-block"
                                        style={{
                                            fontFamily: 'Sarala',
                                            fontSize: '15px'
                                        }}
                                    >Inicio</label>
                                    <Calendar
                                        value={this.props.Filtro.FechaInicio ? new Date(this.props.Filtro.FechaInicio) : new Date()}
                                        name="FechaInicio"
                                        className='p-column-filter-xxl'
                                        onChange={this.props.HandleChange}                                              
                                        //showTime showSeconds
                                        dateFormat="dd/mm/yy" />
                                </div>
                                <div className="p-col-4">
                                    <label
                                        className="p-d-block"
                                        style={{
                                            fontFamily: 'Sarala',
                                            fontSize: '15px'
                                        }}
                                    >Fin</label>
                                    <Calendar                                    
                                        value={this.props.Filtro.FechaFin ? new Date(this.props.Filtro.FechaFin) : new Date()}
                                        name="FechaFin"                                        
                                        onChange={this.props.HandleChange}
                                        className='p-column-filter-xxl'
                                        //showTime showSeconds
                                        dateFormat="dd/mm/yy" />
                                </div>
                                <div className="p-col-4 p-as-center p-mt-3">
                                    <Button
                                        label="Descargar reglas" autoFocus onClick={(event) => this.props.HandleFilter(event)} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )
        }
    }


    BodyEstado(rowData: any) {
        return (
            <React.Fragment>
                {
                    rowData.EstadoRegla.Codigo === '-1' ?
                        <span style={{ color: '#FCB500', fontWeight: 700 }}>No Sincronizado</span>
                        :
                        (
                            rowData.EstadoRegla.Codigo === '0' ?
                                <span style={{ color: '#66BB6A', fontWeight: 700 }}>{rowData.EstadoRegla.Mensaje}</span>
                                :
                                <span style={{ color: '#FF5252', fontWeight: 700 }}>Ocurrió un error</span>
                        )
                }
            </React.Fragment>
        )
    }

    BodyUnico(rowData: any) {
        return (
            <React.Fragment>{rowData.Unico ? <p>1</p> : <p>0</p>}</React.Fragment>
        )
    }

    TemplateFilterEstado(option: any) {
        if (option === 'Sincronizado')
            return <span style={{ color: '#66BB6A', fontWeight: 700 }}>Sincronizado</span>
        else if (option === 'No Sincronizado')
            return <span style={{ color: '#FCB500', fontWeight: 700 }}>No Sincronizado</span>
        else
            return <span style={{ color: '#FF5252', fontWeight: 700 }}>Error</span>
    }

    FilterEstado() {
        return (
            <Dropdown
                value={this.props.SelectedEstado}
                options={this.state.Estados}
                onChange={(e) => this.props.OnChangeEstado(e)}
                itemTemplate={this.TemplateFilterEstado}
                placeholder="Select"
                showClear
                className="p-column-filter-lg" />
        )
    }

    FilterEstadoDesactivacion() {
        return (
            <Dropdown
                value={this.props.SelectedEstadoDesactivacion}
                options={this.state.EstadosDesactivacion}
                onChange={(e) => this.props.OnChangeEstadoDesactivacion(e)}
                //itemTemplate={this.TemplateFilterEstado}
                placeholder="Select"
                showClear
                className="p-column-filter-lg" />
        )
    }

    FilterID() {
        return (
            <InputText
                className="p-column-filter-sm"
                name="fil_ID"
                value={this.props.Paginado.fil_ID}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    FilterDA() {
        return (
            <InputText
                className="p-column-filter"
                name="fil_DA"
                value={this.props.Paginado.fil_DA}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    FilterSucursal() {
        return (
            <InputText
                className="p-column-filter"
                name="fil_Sucursal"
                value={this.props.Paginado.fil_Sucursal}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    FilterRegla() {
        return (
            <InputText
                className="p-column-filter-xl"
                name="fil_Regla"
                value={this.props.Paginado.fil_Regla}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    FilterSKU() {
        return (
            <InputText
                className="p-column-filter"
                name="fil_SKU"
                value={this.props.Paginado.fil_SKU}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    FilterCanal() {
        return (
            <InputText
                className="p-column-filter"
                name="fil_Canal"
                value={this.props.Paginado.fil_Canal}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    FilterSubCanal() {
        return (
            <InputText
                className="p-column-filter"
                name="fil_SubCanal"
                value={this.props.Paginado.fil_SubCanal}
                onChange={(Event) => this.props.Filter(Event)}
                onKeyDown={(Event) => this.props.FilterKeyDown(Event)}
            />
        )
    }

    RowExpansionTemplate = (Data: any) => {
        //console.log(Data)
        switch (Data.IDTipoRegla) {
            case 1:
                return (
                    <div className="orders-subtable">
                        <DataTable value={Data.Bonificacion}>
                            <Column field="SKUBonificar" header="SKUBonificar" />
                            <Column field="CantidadBonificar" header="CantidadBonificar" />
                            <Column field="FactorUnidadBonificar" header="FactorUnidadBonificar" />
                            <Column field="MinCompra" header="MinCompra" />
                            <Column field="MaxCompra" header="MaxCompra" />
                        </DataTable>
                    </div>
                )
            case 2:
                return (
                    <div className="orders-subtable">
                        <DataTable value={Data.Bonificacion}>
                            <Column field="SKUBonificar" header="SKUBonificar" />
                            <Column field="CantidadBonificar" header="CantidadBonificar" />
                            <Column field="FactorUnidadBonificar" header="FactorUnidadBonificar" />
                            <Column field="MinCompra" header="MinCompra" />
                            <Column field="MaxCompra" header="MaxCompra" />
                        </DataTable>
                    </div>
                )
            case 3:
            case 4:
                return (
                    <div className="orders-subtable">
                        <DataTable value={Data.Descuento}>
                            <Column field="Dsct" header="Descuento" />
                            <Column field="MinCompra" header="Minimo" />
                            <Column field="MaxCompra" header="Maximo" />
                        </DataTable>
                    </div>
                )
            case 5:
                return (
                    <div className="orders-subtable">
                        <DataTable value={Data.DescuentoCiclosCortos}>
                            <Column field="DsctIGC" header="Descuento" />
                            <Column field="MaxPedido" header="M. Pedido" />
                            <Column field="MaxCompraPeriodo" header="M. Periodo" />
                            <Column field="Cantidad" header="Cantidades" />
                            <Column field="Agrupacion1SKUS" header="Producto 1" />
                            <Column field="FactorAgrupacion1SKUS" header="Factor" />
                            <Column field="Agrupacion2SKUS" header="Producto 2" />
                            <Column field="FactorAgrupacion2SKUS" header="Factor" />
                            <Column field="Agrupacion3SKUS" header="Producto 3" />
                            <Column field="FactorAgrupacion3SKUS" header="Factor" />
                            <Column field="Agrupacion4SKUS" header="Producto 4" />
                            <Column field="FactorAgrupacion4SKUS" header="Factor" />
                            <Column field="Agrupacion5SKUS" header="Producto 5" />
                            <Column field="FactorAgrupacion5SKUS" header="Factor" />
                        </DataTable>
                    </div>
                )
        }
    }

    BodyNroDocumento(RowData: any) {        
        return (
            <React.Fragment>
                {RowData.NroDocumento.length > 25 ?
                    RowData.NroDocumento.substring(0, 21).replaceAll(';', '; ') + '...'
                    :
                    RowData.NroDocumento.replaceAll(';', '; ')

                }
            </React.Fragment>

        )
    }

    BodyCanal(RowData: any) {
        return (
            <React.Fragment>{RowData.Canal.length > 12 ?
                RowData.Canal.substring(0, 8).replaceAll(';', '; ') + '...'
                :
                RowData.Canal.replaceAll(';', '; ')
            }
            </React.Fragment>
        )
    }

    BodyVendedor(RowData: any) {
        return (
            <React.Fragment>{RowData.CodigoVendedor.replaceAll(';', '; ')}</React.Fragment>
        )
    }

    BodySubCanal(RowData: any) {
        return (
            <React.Fragment>{RowData.SubCanal.replaceAll(';', '; ')}</React.Fragment>
        )
    }

    BodyEstadoReglaDesactivacion(RowData: any) {
        return (
            <React.Fragment>{RowData.EstadoAnulacion}</React.Fragment>
        )
    }

    render() {
        return (
            <div className="datatable-filter-demo">
                <DataTable
                    value={this.props.Regla}
                    selectionMode="single"
                    header={this.Header()}
                    expandedRows={this.state.ExpandedRows}
                    onRowToggle={(e: any) => this.setState({ ExpandedRows: e.data })}
                    rowExpansionTemplate={this.RowExpansionTemplate}
                    className="p-datatable-customers"
                    loading={this.props.Loading}
                    onSelectionChange={e => this.props.HandleSelectRow(e.value.EstadoRegla)}
                    emptyMessage="No se encuentra ninguna regla registrada.">
                    <Column expander style={{ width: '1%', textAlign: 'center' }} />
                    <Column field="ID" header="ID" filter filterElement={this.FilterID()} style={{ width: '3%', textAlign: 'center' }} />
                    <Column field="DA" header="DA" filter filterElement={this.FilterDA()} style={{ width: '5%', textAlign: 'center' }} />
                    <Column field="Sucursal" header="Sucursal" filter filterElement={this.FilterSucursal()} style={{ width: '6%', textAlign: 'center' }} />
                    <Column field="NombreRegla" header="Regla" filter filterElement={this.FilterRegla()} style={{ width: '11%', textAlign: 'center' }} />
                    {/* <Column field="Unico" header="Unico" body={this.BodyUnico} style={{ width: '2%', textAlign: 'center' }} /> */}
                    <Column field="SKUS" header="SKUS" filter filterElement={this.FilterSKU()} style={{ width: '8%', textAlign: 'center' }} />
                    <Column field="FactorUnidadComprar" header="Factor" style={{ width: '2%', textAlign: 'center' }} />
                    <Column field="Canal" header="Canal" filter filterElement={this.FilterCanal()} body={this.BodyCanal} style={{ width: '5%', textAlign: 'center' }} />
                    <Column field="SubCanal" header="SubCanal" filter filterElement={this.FilterSubCanal()} body={this.BodySubCanal} style={{ width: '3%', textAlign: 'center' }} />
                    <Column field="NroDocumento" header="Nr. Documento" body={this.BodyNroDocumento} style={{ width: '7%', textAlign: 'center' }} />
                    <Column field="CodigoVendedor" header="Vendedor" body={this.BodyVendedor} style={{ width: '3%', textAlign: 'center' }} />
                    <Column field="EstadoRegla.Codigo" header="EstadoRegla" body={this.BodyEstado} filter filterElement={this.FilterEstado()} style={{ width: '8%', textAlign: 'center' }} />
                    <Column field="EstadoAnulacion" header="Desactivación" body={this.BodyEstadoReglaDesactivacion} filter filterElement={this.FilterEstadoDesactivacion()} style={{ width: '5%', textAlign: 'center' }} />
                </DataTable>
            </div>
        )
    }
}